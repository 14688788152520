import React, {FC, useState} from 'react'
import { FiChevronDown } from 'react-icons/fi';
import { RiArrowUpSLine } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import { InputField } from '../../../../../components/Inputs';
import { LessonButton } from '../../../../../components/Buttons';

interface Props {
    id: any
    generate: any
}
const AddGenerated:FC<Props> = ({id, generate}) => {
    const [title, setTitle] = useState('')
const [curriculum, setCurriculum] = useState('')
const [objectives, setObjectives] = useState('')
  const [addObjectives, setAddObjectives] = useState('')
    // lesson duration
  const [mins, setMins] = useState<number>(15)
  const reduceMin = () => {
    if(mins > 15){
      setMins(mins - 5)
    }
  }
  const increaseMin = () => {
    if(mins < 60){
      setMins(mins + 5)
    }
  }
  const isValid = () => {
    if(!title || !objectives ){
    return true
    } else return false
  }
  return (
    <>
        <div className="px-5">
        <div className="max-h-[350px] pb-4 overflow-y-auto multi-scroll">
          <div className="lg:mt-4">
            <label className="block mb-2 text-[15px] text-[#87909E]">Lesson Duration</label>
            <div className="rounded-[8px] border mb-3 border-[#DA70D6] border-opacity-30 shadow pr-5">
            <div className="flex justify-between items-center">
              <p className="text-gray-700 px-5">{mins} Minutes</p>
              <div>
                <RiArrowUpSLine className="text-xl text-gray-600 text-center cursor-pointer" onClick={increaseMin}/>
                <FiChevronDown className="text-xl text-gray-600 text-center cursor-pointer" onClick={reduceMin}/>
              </div>
            </div>
            </div>
          </div>
          <div className="lg:mt-4">
            <label className="block mb-2 text-[15px] text-[#87909E]">Choose Resource</label>
            <div className="rounded-[8px] border mb-3 border-[#DA70D6] border-opacity-30 shadow pr-5">
            <select value={curriculum} onChange={(e:any) => setCurriculum(e.target.value)} className="font-inter rounded-[8px] w-full px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline py-2">
              <option>Select an option</option>
              <option value={'School Resource center'}>School Resource center</option>
              <option value={'Tioo Resource center'}>Tioo Resource center</option>
            </select>
            </div>
          </div>
          <InputField
            type="text"
            label="Lesson Title"
            labelSub=""
            placeholder=""
            name="title"
            variant="white"
            lesson
            value={title}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
          />
          
        <div className="mt-3">
            <label className="block mb-2 text-[15px] text-[#87909E]">Lesson Objectives</label>
            <ReactQuill theme="snow" value={objectives} onChange={setObjectives} className="h-28 mb-16" />
        </div>
        <div className="mt-3">
            <label className="block mb-2 text-[15px] text-[#87909E]">Additional Instructions (Optional)</label>
            <ReactQuill theme="snow" value={addObjectives} onChange={setAddObjectives} className="h-28 mb-16"/>
        </div>
        </div>
      </div>
      <div className="px-4 py-4 border-t">
        <LessonButton
          text={"Continue"}
          // variant="default"
        //   onclick={gotoNext}
          invalid={isValid()}
        />
        </div>
    </>
  )
}

export default AddGenerated