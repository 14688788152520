import { createSlice } from "@reduxjs/toolkit";

const lessonSlice = createSlice({
  name: "lesson",
  initialState: {
    uploadClass: "",
    generateData: {},
    generatedNote: 0,
  },
  reducers: {
    saveClassLesson(state, action) {
      state.uploadClass = action.payload;
    },
    saveGenerate(state, action) {
      state.generateData = action.payload;
    },
    saveGenerated(state, action) {
      state.generatedNote = action.payload;
    },
  },
});
export const lessonActions = lessonSlice.actions;
export default lessonSlice;
