import React, { useState, useEffect } from "react";

import DashLayout from "../../../layouts/DashLayout";
import { MdOutlineGroupAdd } from "react-icons/md";
import { RiSearchLine, RiArrowLeftLine } from "react-icons/ri";
import TeacherCard from "./components/TeacherCard";
import LottieAnimation from "../../../components/LottieAnimation";
import searching from "../../../assets/lotties/searching.json";
import CenterModal from "../../../components/CenterModal";
import InviteTeachers from "./components/InviteTeachers";
import EditTeachers from "./components/EditTeachers";
import Subjects from "./components/Subjects";
import Class from "./components/Class";
import { getSchoolTeachers } from "../../../services/authServices";
import { useDispatch } from "react-redux";
import { schoolActions } from "../../../store/schoolSlice";
import { classActions } from "../../../store/classSlice";
import DeleteTeachersModal from "./components/DeleteTeacherModal";
import { authActions } from "../../../store/authSlice";
import { configure } from "../../../services/config";
import { alertActions } from "../../../components/alertActions";
import { useSelector } from "react-redux";
import LoadingCompo from "../../../components/loadingCompo";

interface Data {
  id: number;
  name: string;
}

function Teachers() {
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [nchange, setchange] = useState(1);
  const [item, setItem] = useState<any>("");
  const [schoolTeachers, setSchoolTeachers] = useState([]);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const schoolTeachersinfo = useSelector(
    (state: any) => state.school.teachersInfo
  );

  useEffect(() => {
    setSchoolTeachers(schoolTeachersinfo);
    setloading(true);

    const config = configure(localStorage.getItem("token"));
    let schoolInfo = JSON.parse(
      localStorage.getItem("SchoolManagement") || "{}"
    );

    dispatch(classActions.saveclassInfo(schoolInfo.classes));
    dispatch(schoolActions.saveSchoolManagementInfo(schoolInfo.schoolInfo));
    dispatch(authActions.savetoken(localStorage.getItem("token")));

    const fetchteachers = async () => {
      // setloading(true);
      try {
        const schoolteachers = await getSchoolTeachers(
          schoolInfo.schoolInfo.schoolManagementId,
          config
        );
        schoolteachers == "Network Error" &&
          alertActions.error("Please check Internet connection");
        setSchoolTeachers(schoolteachers?.data.data);
        dispatch(schoolActions.saveTeachersInfo(schoolteachers?.data.data));
        setloading(false);
      } catch (error) {
        // sendErrorMsg(error);
        setloading(false);
      }
    };
    fetchteachers();
  }, [nchange]);

  useEffect(()=>{
    setSchoolTeachers(schoolTeachersinfo);
    if(item){
      setItem(() => {
        return schoolTeachersinfo.filter((tch:any) => tch.id === item.id)[0]
      });
    }
  },[schoolTeachersinfo]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  // Filter the data based on the search query
  const filteredData = schoolTeachers?.filter((item: any) =>
    item.Account.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total pages based on filtered data length and page size
  const totalPages = Math.ceil(filteredData?.length / pageSize);

  // Get the current page data
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = filteredData?.slice(startIndex, endIndex);

  // Handle search query change
  const handleSearchQueryChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const toggleTab = (item: any, tabId: number): void => {
    setActive(true);
    setActiveTab(tabId);
    setItem(item);
  };
  return (
    <>
      <DashLayout title="Teachers">
        <div className="w-full mx-auto">
          <div className="flex items-center justify-between w-full mt-[20px]">
            {schoolTeachers?.length > 0 ? (
              <h3
                className={`font-inter font-medium text-[#030036] opacity-70 text-[22px] ${
                  isFocused ? `hidden` : `block`
                }`}
              >
                Teachers ({schoolTeachers?.length})
              </h3>
            ) : (
              !loading &&
              schoolTeachers?.length < 1 && (
                <div
                  className={`${
                    isFocused ? `hidden` : `flex`
                  } items-start justify-start flex-col mt-[20px] w-full`}
                >
                  <h3 className="text-[#030036] opacity-70 text-[22px] font-normal">
                    No Teachers found!
                  </h3>
                  <p className="text-base text-[#87909E] font-normal leading-[24px] pt-1">
                    You haven’t added any teachers yet, Invite teachers
                  </p>
                </div>
              )
            )}
            <div className="flex items-center gap-[15px]">
              <div
                className={`flex items-center ${
                  isFocused
                    ? "search-box-active  transition border border-[#87909E] border-opacity-30 px-3 py-3 rounded-[6px] shadow-lg"
                    : "w-[160px]"
                }`}
              >
                {isFocused ? (
                  <RiArrowLeftLine className="text-[#87909E] text-[30px]" />
                ) : (
                  <RiSearchLine className="text-[#87909E] text-[30px]" />
                )}

                <input
                  type="text"
                  placeholder={isFocused ? "Search Teacher" : "Search..."}
                  className="focus:outline-none pl-2 py-1 w-full text-base font-normal font-inter text-[#87909E]"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
              </div>
              <div
                className="border border-black border-opacity-10 bg-[#4F46E5] rounded-[8px] px-3 py-4 cursor-pointer w-full max-w-[170px] flex items-center justify-center"
                onClick={() => toggleTab(item, 1)}
              >
                <MdOutlineGroupAdd className="text-white text-[20px]" />
                <h3 className="font-semibold text-[14px] w-full leading-none pl-2 text-white">
                  Invite Teachers
                </h3>
              </div>
            </div>
          </div>
          {loading && currentPageData?.length < 1 && <LoadingCompo />}
          {filteredData?.length === 0 && isFocused ? (
            <div className="flex items-center justify-center w-full my-14">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="h-[100px] w-[100px]">
                  <LottieAnimation data={searching} />
                </div>
                <p className="text-[#030036] opacity-70 text-[18px] font-normal py-4 font-inter">
                  No search results found...
                </p>
              </div>
            </div>
          ) : (
            <div className="w-full mt-[40px] grid grid-cols-4 gap-[24px]">
              {currentPageData?.length > 0 &&
                currentPageData?.map((item: any, index) => (
                  <TeacherCard
                    key={index}
                    del={() => toggleTab(item, 5)}
                    edit={() => toggleTab(item, 2)}
                    teacher={item}
                    subjects={() => toggleTab(item, 3)}
                    classes={() => toggleTab(item, 4)}
                    newchange={nchange}
                  />
                ))}
            </div>
          )}
        </div>
      </DashLayout>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant="small"
      >
        {activeTab === 1 ? (
          <InviteTeachers
            didchange={() => setchange(nchange + 1)}
            close={() => setActive(false)}
          />
        ) : activeTab === 2 ? (
          <EditTeachers
            didchange={() => setchange(nchange + 1)}
            item={item}
            close={() => setActive(false)}
          />
        ) : activeTab === 3 ? (
          <Subjects
            didchange={() => setchange(nchange + 1)}
            item={item}
            close={() => setActive(false)}
          />
        ) : activeTab === 4 ? (
          <Class item={item} close={() => setActive(false)} />
        ) : activeTab === 5 ? (
          <DeleteTeachersModal
            didchange={() => setchange(nchange - 1)}
            item={item}
            close={() => setActive(false)}
          />
        ) : (
          <></>
        )}
      </CenterModal>
    </>
  );
}

export default Teachers;
