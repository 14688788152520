import Tabs from "../../../../components/Tabs";
import UploadLessonIndex from "./UploadLesson";
import GenerateLessons from "./GenerateLessons";


interface UploadLessonModalProps {
  close: () => void;
  id: number
  toggle?: any;
  generate?: any;
}

const UploadLessonModal: React.FC<UploadLessonModalProps> = ({ close, id, toggle, generate }) => {
  const tabs = [
    {
      title: <p>Upload Lessons</p>,
      content: <UploadLessonIndex id={id} upload={toggle}/>,
    },
    {
      title: <p>Generate Lessons</p>,
      content: <GenerateLessons id={id} upload={toggle} generate={generate}/>,
    },
  ]
    return (
      <div>
        <div className="border-b p-5">
          <p className="fw-600 text-2xl">Create Lessons</p>
        </div>
        <div className="min-h-[300px] py-3">
          <Tabs tabs={tabs} isFixed/>
        </div>
      </div>
    )
};

export default UploadLessonModal;
