import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { InputField } from "../../../../components/Inputs";
import { PrimaryButton } from "../../../../components/Buttons";
import { useSelector } from "react-redux";
import {
  createNewClass,
  updateClassName,
} from "../../../../services/authServices";
import { alertActions } from "../../../../components/alertActions";
import { configure } from "../../../../services/config";
import CenterModal from "../../../../components/CenterModal";
import DiscardWarning from "../../../../components/DiscardWarning";

interface EditClassProps {
  close: () => void;
  item?: any;
  didchange: () => void;
}

const CreateNewClass: React.FC<EditClassProps> = ({
  close,
  item,
  didchange,
}) => {
  const [newClassName, setnewClassName] = useState();
  const [loading, setloading] = useState(false);
  const [haschanged, sethaschanged] = useState(false);
  const [active, setActive] = useState(false);

  const userInfo = useSelector((state: any) => state.auth.userInformation);
  const SchoolInfo = useSelector(
    (state: any) => state.school.schoolManagementInfo
  );

  const docheckedit = () => {
    if (haschanged) {
      setActive(true);
    } else {
      close();
    }
  };

  const config = configure(userInfo.token);
  const newNameVal = {
    naming: newClassName,
    school_id: SchoolInfo.schoolManagementId,
  };

  const validate = async () => {
    setloading(true);
    if (!newClassName) {
      alertActions.error("Please enter a class name");
      setloading(false);
      return;
    }

    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    try {
      const data = await createNewClass(newNameVal, config);

      if (data.status == 200) {
        setloading(false);
        alertActions.success("New Class created");
        close();
        didchange();
      }
    } catch (error: any) {
      setloading(false);
    }
  };
  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Add Class
          </h3>
          <div className="cursor-pointer" onClick={docheckedit}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>
        <div className="p-[24px] border-b-[1px]">
          <h3 className="font-semibold text-[18px]  text-[#030036] opacity-70 pb-4"></h3>
          <InputField
            type="text"
            label="Class Name"
            placeholder=""
            name="tags"
            variant="white"
            value={newClassName}
            onChange={(e: any) => {
              setnewClassName(e.target.value);
              sethaschanged(true);
            }}
          />
        </div>
        <div className="p-[24px]">
          <PrimaryButton
            text={loading ? "Loading..." : "Save"}
            variant="dash"
            onClick={validate}
          />
        </div>
      </div>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <DiscardWarning handleAction={close} close={() => setActive(false)} />
      </CenterModal>
    </>
  );
};

export default CreateNewClass;
