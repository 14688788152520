import Request from "./request";
import {
  AddLessons,
  AddLessonsArms,
  SubjectWithLessonCount,
  UpdateLessonTime,
  allClasses,
  allSchoolClasses,
  allSubjects,
  assignClassesToSubject,
  changeStatus,
  classArmStudents,
  classArmSubjects,
  deleteLesson,
  fetchClassArmsByClassId,
  fetchSchoolProfile,
  fetchSchoolTeachers,
  generationLesson,
  getClassTerms,
  getGenerated,
  inviteBulkStudentsUrl,
  removeClassesFromSubject,
} from "./routes";

/* ----------------------------- SCHOOL HANDLERS ---------------------------- */
export const loadSchoolProfile = async () => Request.get(fetchSchoolProfile());

/* --------------------------------- CLASSES -------------------------------- */
export const getClasses = async () => Request.get(allSchoolClasses());

export const getClassArmStudents = async (id) =>
  Request.get(classArmStudents(id));

export const getClassArmSubjects = async (id) =>
  Request.get(classArmSubjects(id));

export const getClassArmsByClass = async (id) =>
  Request.get(fetchClassArmsByClassId(id));

/* -------------------------------- SUBJECTS -------------------------------- */
export const getSubjects = async (id) => Request.get(allSubjects(id));

export const getSubjectClasses = async (id) => Request.get(allClasses(id));

export const getSchoolTerm = async (id) => Request.get(getClassTerms(id));

export const assignClassToSubject = async (items) =>
  Request.post(assignClassesToSubject(), items);

export const removeClassFromSubject = async (items) =>
  Request.post(removeClassesFromSubject(), items);

export const getSubjectWithLessonCount = async () =>
  Request.get(SubjectWithLessonCount());

export const updateTimeline = async (items, id) =>
  Request.put(UpdateLessonTime(id), items);

export const AddNewLesson = async (items, id) =>
  Request.post(AddLessons(id), items);

export const AddNewLessonArms = async (items, id) =>
  Request.post(AddLessonsArms(id), items);

export const DeleteLesson = async (items, id) =>
  Request.delete(deleteLesson(items, id));

export const EditLessonStatus = async (id, status) => Request.put(changeStatus(id, status))

export const generateLesson = async (items) =>
Request.post(generationLesson(), items);

export const fetchGenerated =  async (id) =>
Request.get(getGenerated(id));

export const editGenerateLesson = async (items, id) =>
Request.put(getGenerated(id), items);

/* -------------------------------- STUDENTS -------------------------------- */
export const inviteBulkStudents = async (data) =>
  Request.post(inviteBulkStudentsUrl(), data);

/* -------------------------------- TEACHERS -------------------------------- */
export const getSchoolTeachers = async (id) =>
  Request.get(fetchSchoolTeachers(id));
