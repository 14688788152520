import React, { useState } from "react";
import { useSelector } from "react-redux";
import { deleteClass } from "../../../../services/authServices";
import { alertActions } from "../../../../components/alertActions";
import { configure } from "../../../../services/config";
import DeleteModal from "../../../../components/deleteModal";

interface DeleteSubjectModalProps {
  close: () => void;
  item: any;
  didchange: () => void;
}

const DeleteClassModal: React.FC<DeleteSubjectModalProps> = ({
  close,
  item,
  didchange,
}) => {
  const [loading, setloading] = useState(false);
  const userInfo = useSelector((state: any) => state.auth.userInformation);
  const config = configure(userInfo.token);

  const handleDeleting = async () => {
    setloading(true);
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    try {
      const res = await deleteClass(item.id, config);

      if (res.status == 200) {
        alertActions.success("Class Deleted successfully");
        setloading(false);
        close();
        didchange();
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <DeleteModal
      item={item}
      close={close}
      handleDelete={handleDeleting}
      title={"Delete Class : " + item.naming}
      aliasname="Class"
      loading={loading}
    />
  );
};

export default DeleteClassModal;
