import React, {FC, useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';

interface Props {
    data: any
    lesson: any
    getValue: any
}
const DisplayText:FC<Props> = ({data, lesson, getValue}) => {
    const [value, setValue] = useState('')
    useEffect(() => {
        if(lesson){
            setValue(lesson.generated)
            getValue(lesson.generated)
        }
    }, [lesson])
    const handleChange = (value:any) => {
        setValue(value)
        getValue(value)
    }
        
  return (
    <>
        <div className='bg-white min-h-[500px] p-8 rounded-[4px] edit-shade'>
        {
            value && <ReactQuill theme="bubble" defaultValue={value} onChange={handleChange} modules={{ toolbar: [""] }} className="min-h-[500px]" />
        }
        </div>
        <div className='pt-12 flex justify-end'>
            {/* <button className='px-7 py-3 rounded-lg bg-[#4F46E5] text-white font-[500]'>Create Activity</button> */}
        </div>
    </>
  )
}

export default DisplayText