import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/dashboard/home";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Subjects from "./pages/dashboard/subjects";
import Teachers from "./pages/dashboard/teachers";
import Teacher from "./pages/auths/teacher";
import Student from "./pages/auths/student";
import Students from "./pages/dashboard/students";
import Lessons from "./pages/dashboard/lessons";
import BackButtonGuard from "./services/backButtonGuard";
import TeacherSubjects from "./pages/dashboard/teacherDashboard/subjects";
import TeacherStudent from "./pages/dashboard/teacherDashboard/students";
import TeacherLessons from "./pages/dashboard/teacherDashboard/lessons";
import SubjectLessonsDetails from "./pages/dashboard/teacherDashboard/lessons/details";
import GlobalScope from "./global";
import LogToken from "./pages/auths/LogToken";
import { ROUTES } from "./global/routes";
import UploadedLesson from "./pages/dashboard/lessons/uploaded-lessons";
import EditLessonNote from "./pages/dashboard/lessons/editLessonNote";

function App() {
  
  const [token, setToken] = useState(() => localStorage.getItem("token"));

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    if(token){GlobalScope();}
  }, [token]);
  return (
    <>
      <BackButtonGuard />
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTES.AUTH_TOKEN}
            element={
              <LogToken/>
            }
          />
          <Route
            path={ROUTES.HOME}
            element={
              <Home />
            }
          />
          <Route
            path={ROUTES.SUBJECTS}
            element={
              <Subjects />
            }
          />
          <Route
            path={ROUTES.TEACHER_SUBJECTS}
            element={
              <TeacherSubjects />
            }
          />
          <Route
            path={ROUTES.STUDENTS}
            element={
              <Students />
            }
          />
          <Route path={ROUTES.STUDENT} element={<Student />} />
          <Route
            path={ROUTES.TEACHER_STUDENTS}
            element={
              <TeacherStudent/>
            }
          />
          <Route
            path={ROUTES.TEACHERS}
            element={
              <Teachers />
            }
          />
          <Route
            path={ROUTES.LESSONS}
            element={
              <Lessons />
            }
          />
          <Route
            path={ROUTES.TEACHER_LESSONS}
            element={
              <TeacherLessons />
            }
          />
          <Route
            path={ROUTES.TEACHER}
            element={
              <Teacher />
            }
          />
          <Route
            path={ROUTES.UPLOADED_LESSONS}
            element={
              <UploadedLesson/>
            }
          />
          <Route
            path={ROUTES.EDIT_LESSON_NOTE}
            element={
              <EditLessonNote/>
            }
          />
          <Route
            path={ROUTES.TEACHER_LESSON}
            element={
              <SubjectLessonsDetails />
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer className={"mx-auto"} theme="colored" />
    </>
  );
}

export default App;
