import React, { useState, useEffect } from "react";
import DashLayout from "../../../layouts/DashLayout";
import CenterModal from "../../../components/CenterModal";
import AddSubjectCard from "./components/AddSubjectCard";
import AddSubjectModal from "./components/AddSubjectModal";
import SubjectCard from "./components/SubjectCard";
import ClassesModal from "./components/ClassesModal";
import TeachersModal from "./components/TeachersModal";
import { alertActions } from "../../../components/alertActions";
import AssignTeachersModal from "./components/AssignTeachers";
import EditSubjectModal from "./components/EditSubjectModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteSubjectModal from "./components/DeleteSubjectModal";

import AssignClassModal from "./components/AssignClassModal";
import { getClasses, getSubjects } from "../../../api";
import LoadingCompo from "../../../components/loadingCompo";
import { getclassWithArms } from "../../../services/authServices";
import { classActions } from "../../../store/classSlice";

function Subjects() {
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [item, setItem] = useState("");
  const [allsubject, setallsubject] = useState([]);
  const [subjectModal, setSubjectModal] = useState(false);
  const [teacherModal, setTeacherModal] = useState(false);
  const [classModal, setClassModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nchange, setchange] = useState(1);
  const dispatch = useDispatch();
  const schoolManagement = useSelector((state: any) => state.school.schoolManagementInfo);
  const schoolSubjects = useSelector(
    (state: any) => state.school.schoolSubject
  );


  const getAllSubjects = () => {
    getSubjects(schoolManagement?.schoolManagementId)
    .then((response) => {
      setLoading(false);
      setallsubject(response.data);
    })
    .catch((error) => {
      setLoading(false);
    });
  }

   useEffect(() => {
     setLoading(true);
     getAllSubjects();
   }, [nchange, schoolManagement]);

  const toggleTab = (item: any, tabId: number): void => {
    setActive(true);
    setActiveTab(tabId);
    setItem(item);
  };

  const toggleTeacher = () => {
    setActive(false);
    setTeacherModal(true);
  };

  const colors: string[] = [
    "bg-[#AE1E56]",
    "bg-[#776DB4]",
    "bg-[#148CFA]",
    "bg-[#DDA0DD]",
    "bg-[#681AB0]",
    "bg-[#330072]",
    "bg-[#4F46E5]",
  ];
  
  return (
    <>
      <DashLayout title="Subjects">
        <div className="w-full mx-auto">
          <div className="w-full flex items-start justify-start flex-col mt-[20px]">
            {}
            {/* {loading && allsubject?.length == 0 && <LoadingCompo />} */}
            {allsubject?.length > 0 ? (
              <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[22px]">
                My Subjects (
                {allsubject?.length > 0
                  ? allsubject?.length
                  : schoolSubjects?.length
                  ? schoolSubjects?.length
                  : 0}
                )
              </h3>
            ) : (
              !loading &&
              allsubject?.length < 1 && (
                <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[22px]">
                  You haven’t added any subjects
                </h3>
              )
            )}
            <div className="mt-[32px] w-full grid grid-cols-3 gap-[24px]">
              {allsubject?.map((item: any, index) => {
                const colorIndex = index % colors.length;
                const color = colors[colorIndex];

                return (
                  <SubjectCard
                    item={item}
                    subject={item.naming}
                    desc={item.description}
                    color={color}
                    del={() => toggleTab(item, 4)}
                    edit={() => toggleTab(item, 1)}
                    openClasses={() => toggleTab(item, 2)}
                    openTeachers={() => toggleTab(item, 3)}
                    newchange={nchange}
                  />
                );
              })}
              <AddSubjectCard
                title="Add subjects"
                desc="e.g English, maths, history, etc "
                onClick={() => setSubjectModal(!subjectModal)}
                  />
            </div>
          </div>
        </div>
      </DashLayout>
      <CenterModal isOpen={subjectModal} onClose={() => setSubjectModal(false)}>
        <AddSubjectModal
          didchange={() => setchange(nchange + 1)}
          close={() => setSubjectModal(false)}
        />
      </CenterModal>
      <CenterModal isOpen={active} onClose={() => setActive(false)}>
        {activeTab === 1 ? (
          <EditSubjectModal
            didchange={() => setchange(nchange + 1)}
            item={item}
            close={() => setActive(false)}
          />
        ) : activeTab === 2 ? (
          <ClassesModal
            item={item}
            close={() => setActive(false)}
            addNewClass={() => {
              setClassModal(true);
              setActive(false);
            }}
            onDeleteClass={()=>{
              getAllSubjects();
            }}
          />
        ) : activeTab === 3 ? (
          <TeachersModal
            item={item}
            close={() => setActive(false)}
            assignTeachers={() => toggleTeacher()}
          />
        ) : activeTab === 4 ? (
          <DeleteSubjectModal item={item} close={() => {
            setActive(false)
            getAllSubjects()
          }} />
        ) : (
          <></>
        )}
      </CenterModal>

      <CenterModal isOpen={teacherModal} onClose={() => setTeacherModal(false)}>
        <AssignTeachersModal
          didchange={() => setchange(nchange + 1)}
          item={item}
          close={() => setTeacherModal(false)}
        />
      </CenterModal>
      <CenterModal isOpen={classModal} onClose={() => setClassModal(false)}>
        <AssignClassModal
          didchange={() => {
            setchange(nchange + 1);
            getAllSubjects();
          }}
          item={item}
          close={() => setClassModal(false)}
        />
      </CenterModal>
    </>
  );
}

export default Subjects;
