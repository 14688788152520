import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FailureCheck } from "../../components/AlertCheck";
import { ThreeCirclesLoading } from "../../components/Loading";
import { useDispatch } from "react-redux";
import axios from "axios";
import { SIGNIN_URL } from "../../services/url";
import { authActions } from "../../store/authSlice";
import { alertActions } from "../../components/alertActions";
import logo from '../../assets/images/logo.png'

const LogToken = () => {
  const [isBusy, setIsBusy] = useState(true);
  const [failed, setFailed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const validateToken = async (token: any) => {
    try {
      const res = await axios.get(`${SIGNIN_URL}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status == 200) {
        const updatedUser = {
          ...res?.data.data,
          token: token,
        };
        dispatch(authActions.saveUserInfo(updatedUser));
        localStorage.setItem("token", token);
        alertActions.success("Login Successful");

        setIsBusy(false);
        navigate("/");
      }
    } catch (error: any) {
      setIsBusy(false);
      setFailed(true);
      if (error?.response?.data?.message == "Username or Password don't match")
        alertActions.error("Username or Password don't match");
    }
  };
  useEffect(() => {
    if (token) {
      validateToken(token);
    } // eslint-disable-next-line
  }, [token]);
  return (
    <>
      <>
        <div className="h-screen bg-[#E3E2FF] flex justify-center items-center">
          <div className="">
            <div className="">
              {isBusy && (
                <div className="">
                  <div className="flex justify-center">
                    <ThreeCirclesLoading size="200" color="#4f46e5" />
                  </div>
                </div>
              )}
              {failed && (
                <div className="">
                  <p className="text-2xl text-center fw-600">
                    Failed Login Attempt
                  </p>
                  <div className="mt-6 lg:mt-12">
                    <FailureCheck />
                  </div>
                  <div className="lg:w-5/12 mx-auto text-center">
                    <p className="text-center px-2 lg:px-0">
                      We're sorry, but it looks like your session token has
                      expired, please login to gain access to your dashboard.
                    </p>
                    <div className="lg:w-10/12 mt-8 mx-auto">
                      <a
                        href="https://student.hellotioo.com/login"
                        className="btn-like"
                      >
                        Return to Login
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="fixed top-3 left-4">
            <img src={logo} alt="logo" className="w-24" />
          </div>
        </div>
      </>
    </>
  );
};

export default LogToken;
